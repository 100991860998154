import React from 'react'
import Container from '../../components/container'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'

import { responsiveTitle1,responsiveTitle4,responsiveParaReg } from '../../components/typography.module.css'
import style from '../../components/disruption.module.css'


////////////////////////////////////////////
// THIS PAGE IS REMOVED VIA Gatsby-node.js//
////////////////////////////////////////////




const DisruptionPage = props => {
  return (
    <Layout site='Adelaide'>
      <SEO title={'Disruption'} />

      <Container>

        <div className={style.headerImage}>
          <iframe src="https://player.vimeo.com/video/517949922"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" frameBorder="0"  allowFullScreen="">
          </iframe>
        </div>
        <div className={style.title}>
          <h1 className={responsiveTitle1}> 
            people don’t read, they have even less time to sit through film. 
            what pullout thoughts / ideas / messaging can we have here that a brief scroll of this page leaves them with?
          </h1>
        </div>

        <div className={style.cardItems}>
          <CardItems 
            head="cultural research labs"  
            copy="Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book."
            videoLink="https://player.vimeo.com/video/517949922"
          />
          <CardItems 
            head="edges"  
            copy="Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book."
            videoLink="https://player.vimeo.com/video/517949922"
          />
          <CardItems 
            head="Backslash"  
            copy="Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book."
            videoLink="https://player.vimeo.com/video/517949922"
          />

        </div>

        {/* <h1 className={responsiveTitle1}> Disruption  Page</h1> */}
      </Container>
    </Layout>
  )
}


const CardItems = props => {
  const {head, copy, videoLink} = props

  return (
    <div className={style.card}>


    <div className={style.headerTitle}>
      <h6 className={responsiveTitle4}>{head}</h6>
    </div> 
    <div className={style.cardGroup}>
      <div className={style.copy}>
        <p className={responsiveParaReg}>{copy}</p>
      </div> 

      <div className={style.cardVideoContain}>
        <iframe src={videoLink}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" frameBorder="0" allowFullScreen="">
        </iframe>
      </div> 
    </div> 



    </div> 



  )




}




export default DisruptionPage
